<template>
  <div>
    <h3>{{ $t("profile.active.title") }}</h3>

    <p class="green" v-if="isActive">
      {{ $t("profile.active.current.active") }}
    </p>
    <p class="orange" v-if="isRequested">
      {{ $t("profile.active.current.requested") }}
    </p>
    <p class="red" v-if="canApply">
      {{ $t("profile.active.current.inactive") }}
    </p>
    <p class="red" v-if="isDenied">
      {{ $t("profile.active.current.denied") }}
    </p>

    <div v-if="isActive">
      <p>{{ $t("profile.active.status.active") }}</p>
      <div class="vca-center">
        <vca-cancel-button
          @click="withdrawActive()"
          :placeholder="$t('profile.active.actions.active')"
        />
      </div>
      <p>{{ $t("profile.active.status.active_reject") }}</p>
    </div>

    <div v-if="isRequested">
      <p>{{ $t("profile.active.status.requested") }}</p>
      <div class="vca-center">
        <button @click="withdrawActive()" class="vca-button-small">
          {{ $t("profile.active.actions.requested") }}
        </button>
      </div>
    </div>

    <div v-if="canApply">
      <p>{{ $t("profile.active.status.apply") }}</p>
      <div class="vca-center">
        <button @click="requestActive()" class="vca-button-small">
          {{ $t("profile.active.actions.apply") }}
        </button>
      </div>
    </div>
    <div v-if="isDenied">
      <p class="bold">{{ $t("profile.active.status.important") }}</p>
      <p v-if="!hasCrew">{{ $t("profile.active.status.no_crew") }}</p>
      <div class="vca-center">
        <button class="vca-button-small" disabled>
          {{ $t("profile.active.actions.cannot_apply") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ActiveFlag",
  computed: {
    ...mapGetters({
      isActive: "user/active/isActive",
      isRequested: "user/active/isRequested",
      canApply: "user/active/canApply",
      hasCrew: "user/crew/hasCrew",
    }),
    isDenied() {
      return (
        !this.canApply && !this.hasCrew && !this.isActive && !this.isRequested
      );
    },
  },
  methods: {
    requestActive() {
      if (!confirm(this.$t("profile.active.messages.request"))) {
        return false;
      }
      this.$store.dispatch({ type: "user/active/requestActive" });
    },
    withdrawActive() {
      if (!confirm(this.$t("profile.active.messages.withdraw"))) {
        return false;
      }
      this.$store.dispatch({ type: "user/active/withdrawActive" }).then(() => {
        this.$store.dispatch("refresh");
      });
    },
  },
};
</script>
